import styled from "styled-components"
import { breakPoints } from "./break-points"

export const Author = styled.div`
	font-weight:bold;
`

export const BRMobile = styled.br`
  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`

export const SubHeader = styled.h2`
  color: var(--primary);
  margin-bottom: 1rem;
  font: 400 1.25rem/1.75rem var(--display-font);
`

export const Header = styled.h1`
  font: 400 1.75rem/2.25rem var(--display-font);
  margin-bottom: 1.75rem;

  @media (min-width: 768px) {
    font: 400 2rem/2.5rem var(--display-font);
  }
`


export const HeaderCenter = styled(Header)`
	text-align: center;
`
export const SubHeaderCenter = styled(SubHeader)`
	text-align: center;
`

import { createContext } from "react"
import { observable } from "mobx"
import { string } from "prop-types"

class UIStore {
  @observable mobileMenu = {
    isOpen: false,
    toggle: () => {
      this.mobileMenu.isOpen = !this.mobileMenu.isOpen
    },
  }

  @observable addFeaturesSelected = {
    plugIns: false,
    additionalPages: false,
    navigationMenus: false,
    animations: false,
    pageTransitions: false,
    iconsAndGraphics: false,
    empty: false,
  }

  @observable packageSelected = {
    package: "",
    plan: "",
  }
}

export const UIStoreContext = createContext(new UIStore())

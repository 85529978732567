import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { links } from "../../content/menu"
import instagramLogoPath from "../../icons/socials/instagram.svg"
import facebookLogoPath from "../../icons/socials/facebook.svg"
import linkedInLogoPath from "../../icons/socials/linkedIn.png"
import { SectionContainer } from "../shared/containers"
import { breakPoints } from "../shared/break-points"

export const Footer = (): JSX.Element => {
  const LinksHire = links.hire.map(link => (
    <Link key={link.name} to={link.url}>
      {link.name}
    </Link>
  ))
  const LinksHelp = links.help.map(link => (
    <Link key={link.name} to={link.url}>
      {link.name}
    </Link>
  ))

  return (
    <>
      <Container>
        <Content>
          <LinkGroup>{LinksHire}</LinkGroup>
          <LinkGroup>{LinksHelp}</LinkGroup>
          <SocialMediaLinks>
            <a
              href="https://www.instagram.com/whomediastudio/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={instagramLogoPath} aria-label="Instagram" />
            </a>
            <a
              href="https://facebook.com/whomediastudio/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={facebookLogoPath} aria-label="Facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/who-studio-inc/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={linkedInLogoPath} aria-label="LinkedIn" />
            </a>
          </SocialMediaLinks>
        </Content>
        </Container>
        <BuiltWith>©whostudioinc. | Built With <span style={{fontFamily: "var(--body-font)"}} >Who.</span></BuiltWith>
      </>
  )
}

const Container = styled.section`
  grid-column: 1/-1;
  padding: 1.5rem 0;
  border-top: 3px solid var(--grey);
	@media (min-width: ${breakPoints.tablet}) {
    padding: 2.5rem 0;
	}
	>* {
		grid-column:2;
	}
`

const Content = styled.footer`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: start;
  justify-items: center;


  @media (min-width: 768px) {
    justify-content: center;
    justify-items: start;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
`

const BuiltWith = styled.h6`
  grid-column: 1/-1;
  width: 100%;
  text-align: center;
  font: 0.8rem/0.8rem var(--display-font);
  background-color: var(--grey);
  padding: 0.6rem 0;

  @media (min-width: 768px) {
    font: 1.1rem/1.1rem var(--ultra);
    padding: 1rem 0;
  }
`

const LinkGroup = styled.div`

  a {
    font: 1.1rem/1.1rem var(--display-font);
    display: block;
    padding: 0.5rem 0;
  }

  @media (min-width: 768px) {
    padding: 0;
    margin-left: 2rem;
    a {
      font: 1.2rem/1.2rem var(--display-font);
    }
  }
`

const Icon = styled.img`
  height: 32px;
  width: auto;
  padding: 4px;

  @media (min-width: 768px) {
    padding: 0;
    height: 28px;
    margin-left: 8px;
  }
`

const SocialMediaLinks = styled.div`
  filter: invert(70%) sepia(50%);
  justify-self: center;
  align-self: end;

  @media (min-width: 768px) {
    padding: 0;
    margin: 0 0 -2px 0;
    grid-column: auto / -1;
    justify-self: center;
    align-self: end;
  }
`

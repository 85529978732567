import * as React from "react"
import styled from "styled-components"

interface Props {
  isSelected?: boolean
}

export const SelectableLink: React.FC<Props> = props => {
  const { isSelected, children } = props
  const ListItem = styled.li`
    font: 1rem/1rem var(--display-font);
    margin: 0 1rem;
    display: inline;
    border-bottom: 2px
      ${isSelected ? "var(--underline-green)" : "var(--invisible)"} solid;

    :hover {
      border-bottom: 2px solid var(--underline-green);
    }
  `

  return <ListItem>{children}</ListItem>
}

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Header } from "../components/header"
import { Menu } from "../components/menu/menu"
import { Footer } from "./footer/footer"
import favicon from "../logos/favicon.ico"
import Helmet from "react-helmet"

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Menu />
      <Header />
      <Content>{children}</Content>
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Content = styled.div`
  display: grid;
  grid-template-columns: var(--grid);
  > * {
    grid-column: 1/-1;
  }
`

import * as React from "react"
import styled from "styled-components"
import whoStudioBlackLogo from "../logos/whostudioBlack.svg"
import lightbulb from "../logos/lightbulb.svg"
import { Burger } from "./menu/burger"
import { MenuDesktop } from "./menuDesktop/menuDesktop"
import { Link } from "gatsby"

export const Header = (): JSX.Element => {
  return (
    <Container>
      <LogoContainer to="/">
        <Lightbulb
          src={lightbulb}
          alt="Stylized outline of a lightbulb/question mark to the left of the company logo."
        />
        <WhoStudioLogo
          src={whoStudioBlackLogo}
          alt="Who Media Studio, company logo."
        />
      </LogoContainer>
      <Burger /> {/* this is fixed to the top right */}
      <Desktop>
        <MenuDesktop />
      </Desktop>
    </Container>
  )
}

const Container = styled.header`
  display: flex;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  padding: 2px;
  position: sticky;
  top:0;
  left:0;
  right:0;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--white);
  z-index: 1;

  @media (min-width: 768px) {
    height: 90px;
    align-items: center;
    padding: 0 2rem 0 0;
  }
`

const Desktop = styled.div`
  display: none;

  @media (min-width: 1060px) {
    display: initial;
  }
`

const Lightbulb = styled.img`
  height: 45px;
  width: auto;

  @media (min-width: 768px) {
    height: 70px;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  align-items: flex-start;
  align-self: center;
`

const WhoStudioLogo = styled.img`
  height: 15px;
  width: auto;
  align-self: center;

  @media (min-width: 768px) {
    height: 24px;
  }
`

import * as React from "react"
import styled from "styled-components"
import burgerLogo from "./icons/burger.svg"
import { observer } from "mobx-react-lite"
import { UIStoreContext } from "../../stores/UIStore"

export const Burger = observer(
  (): JSX.Element => {
    const { mobileMenu } = React.useContext(UIStoreContext)

    return (
      <>
        <Icon
          onClick={mobileMenu.toggle}
          src={burgerLogo}
          alt="button to expand mobile menu"
        />
      </>
    )
  }
)

const Icon = styled.img`
  border-radius: 5px;
  border: 2px var(--white) solid;
  height: 28px;
  margin: 16px;

  @media (min-width: 1060px) {
    display: none;
  }
`

export const links = {
  hire: [
    {
      name: "What We Do",
      url: "/what-we-do",
    },
    {
      name: "Packages",
      url: "/packages",
    },
    {
      name: "Careers",
      url: "/careers",
    },
  ],

  help: [
    {
      name: "Who We Are",
      url: "/who-we-are",
    },
    {
      name: "Resources",
      url: "/resources",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
  ],
}

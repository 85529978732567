import * as React from "react"
import styled, { keyframes } from "styled-components"
import { observer } from "mobx-react-lite"
import { UIStoreContext } from "../../stores/UIStore"
import { Link } from "gatsby"
import { links } from "../../content/menu"

import whoStudioBlackLogo from "../../logos/whostudioBlack.svg"
import lightbulb from "../../logos/lightbulb.svg"

const slide = keyframes`
  from {
    transform: translateY(-50vh);
  }

  to {
    transform: translateY(0);
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Menu = observer((): JSX.Element | null => {
  const { mobileMenu } = React.useContext(UIStoreContext)

  const LinksHire = links.hire.map(link => (
    <li onClick={mobileMenu.toggle} key={link.url}>
      <Link to={link.url}>{link.name}</Link>
    </li>
  ))
  const LinksHelp = links.help.map(link => (
    <li onClick={mobileMenu.toggle} key={link.url}>
      <Link to={link.url}>{link.name}</Link>
    </li>
  ))

  const Container = styled.div`
    position: fixed;
    animation: ${slide} 0.3s ease-in;
    width: 100%;
    z-index: 2;
    background-color: var(--grey);
    grid-column: 1/-1;
  `

  const Backdrop = styled.div`
    position: fixed;
    animation: ${fadeIn} 0.3s ease-in;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    grid-column: 1/-1;
    width: 100%;
    height: 100%;
  `
  return mobileMenu.isOpen ? (
    <>
      <Container id="mobile-menu">
        <LogoContainer>
          <Link to="/" onClick={mobileMenu.toggle}>
            <Lightbulb
              src={lightbulb}
              alt="Stylized outline of a lightbulb/question mark to the left of the company logo."
            />
          </Link>
          <Link to="/" onClick={mobileMenu.toggle}>
            <WhoStudioLogo
              src={whoStudioBlackLogo}
              alt="Who Media Studio, company logo."
            />
          </Link>
          <Close onClick={mobileMenu.toggle}>✕</Close>
        </LogoContainer>

        <LinkContainer>
          <Links>
            <Title>hire</Title>
            {LinksHire}
          </Links>
          <Links>
            <Title>help</Title>
            {LinksHelp}
          </Links>
        </LinkContainer>
      </Container>
      <Backdrop onClick={mobileMenu.toggle} />
    </>
  ) : null
})

const Close = styled.button`
  /* display: block; */
  margin: 8px 15px 0 auto;
  padding: 0px;
  background: none;
  border: none;
  outline: none;
  font: 1.8rem/1.8rem var(--display-font);
`

const Lightbulb = styled.img`
  height: 45px;
  width: auto;
`

const Links = styled.ul`
  list-style: none;
  padding-bottom: 4rem;
  > li {
    font: 1rem/1rem var(--display-font);
    padding: 1rem 3rem;
  }
`

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  padding-left: 0.5rem;
  > a {
    padding-top: 0.5rem;
  }
`

const Title = styled.h1`
  font: 1.4rem/1.4rem var(--display-font);
  margin: 0 0 1.25rem 36px;
`

const WhoStudioLogo = styled.img`
  height: 15px;
  width: auto;
`

import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { links } from "../../content/menu"
import { SelectableLink } from "./selectableLink"
import Arrow from "../../icons/gui/rightArrow.svg"

export const MenuDesktop = () => {
  const curPath = typeof window !== "undefined" && window.location.pathname
  const isHere = (url: string): boolean => url === curPath

  const LinksHire = links.hire.map(link => (
    <SelectableLink key={link.url} isSelected={isHere(link.url)}>
      <Link to={link.url}>{link.name}</Link>
    </SelectableLink>
  ))

  const LinksHelp = links.help.map(link => (
    <SelectableLink key={link.url} isSelected={isHere(link.url)}>
      <Link to={link.url}>{link.name}</Link>
    </SelectableLink>
  ))

  return (
    <Links>
      <Category>
        <Raise>hire</Raise>
        <RightArrow src={Arrow} />
      </Category>
      {LinksHire}
      <Category>
        <Raise>help</Raise> <RightArrow src={Arrow} />
      </Category>
      {LinksHelp}
    </Links>
  )
}
const Raise = styled.span`
  border-bottom: 3px solid var(--invisible);
`

const Category = styled.h3`
  color: var(--brand-green);
  display: flex;
  align-items: center;
  font: bold 1.1rem/1.1rem var(--display-font);
  margin-left: 1.5rem;

  @media (min-width: 1250px) {
    font: bold 1.2rem/1.2rem var(--display-font);
    margin-left: 3rem;
  }
`

const Links = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  a {
    font: 1rem/1rem var(--body-font);
    text-transform: lowercase;
  }

  @media (min-width: 1250px) {
    a {
      font: 1.2rem/1.2rem var(--body-font);
    }
  }
`
const RightArrow = styled.img`
  margin: 0 0.5rem;

  @media (min-width: 1250px) {
    margin: 0 1rem;
  }
`

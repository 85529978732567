import styled from "styled-components"

export const Button = styled.button`
  background-color: var(--brand-green);
  font: 1.1rem/1.1rem var(--body-font);
  color: var(--white);
  text-align: center;
  border: none;
  padding: 10px 50px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  :hover {
    filter: brightness(130%);
    transform: translateY(-3px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

export const InputButton = styled.input`
  background-color: var(--brand-green);
  font: 1.1rem/1.1rem var(--body-font);
  color: var(--white);
  text-align: center;
  border: none;
  padding: 10px 50px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  :hover {
    filter: brightness(130%);
    transform: translateY(-3px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`
